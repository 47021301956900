<template>
  <div>
    <span class="fw-600 d-block fs-18 mb-2"> Social networks </span>
    <form @submit.prevent="proceed">
      <div
        class="grid grid__layout grid__mobile gap-3 mt-3"
        v-for="(an, i) in addedNetworks"
        :key="an.site"
      >
        <div class="col-4" :style="{ zIndex: 100 - i }">
          <Dropdown
            :options="computedSocialNetworks"
            @selected="setNetworkName($event, i)"
            :placeholder="an.site || 'Network'"
            :border="true"
          />
        </div>
        <div class="col-7">
          <Input
            type="url"
            placeholder="Network link"
            v-model="an.url"
            name="network link"
          />
        </div>

        <div class="col-1 pt-1">
          <button
            @click="removeNework(i)"
            class="flex cursor-pointer"
            v-show="i > 0"
          >
            <img
              src="../../../../assets/icons/close-icon-2.svg"
              alt="close-icon"
            />
          </button>
        </div>
      </div>

      <div class="mt-3 flex flex__center">
        <Button
          theme="dashed"
          @click="addMoreNetwork"
          class="w-23"
          btnType="button"
        >
          <div class="flex" style="margin-right: 10px">
            <img src="../../../../assets/icons/add-icon.svg" alt="add-icon" />
          </div>
          <span class="fs-14 fw-600" style="color: #8b90a0">
            Add more network
          </span>
        </Button>
      </div>

      <div class="bb-1 my-3" />
      <div class="flex flex__justify-end onboarding__submit-btn">
        <Button
          theme="grey"
          @click="$emit('decrementStep')"
          class="w-23"
          btnType="button"
        >
          Go Back
        </Button>
        <Button
          theme="blue"
          :disabled="btnDisabled || !addedNetworks[0].url"
          class="ml-2 w-23"
        >
          Proceed
        </Button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import Button from '../../../../components/Button';
import publicService from '../../../../services/api/publicService';

export default {
  props: {
    onboardingData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Button
  },
  data() {
    return {
      socialNetworks: [
        // { name: "Facebook", value: "facebook" },
        // { name: "Twitter", value: "twitter" },
        // { name: "Linked In", value: "linkedin" },
        // { name: "Github", value: "github" }
      ],
      addedNetworks: [{ site: '', url: '' }],
      loading: false
    };
  },
  computed: {
    btnDisabled() {
      if (this.addedNetworks.length < 1) {
        return true;
      }

      for (let i = 0; i < this.addedNetworks.length; i++) {
        if (!this.addedNetworks[i].site || !this.addedNetworks[i].url) {
          return true;
        }
      }

      return false;
    },
    computedSocialNetworks() {
      return this.socialNetworks.filter((sn) => {
        return !this.addedNetworks.some((an) => an.site === sn.value);
      });
    }
  },

  mounted() {
    publicService
      .getNetworkList()
      .then((res) => {
        this.socialNetworks = res.data.networks.map((item) => ({
          name: item.title,
          value: item.title
        }));
      })
      .catch((e) => {})
      .finally(() => {});
  },
  methods: {
    ...mapMutations({
      setSocialNetworks: 'authnew/setSocialNetworks'
    }),
    setNetworkName(event, i) {
      console.log(event);
      // this.formData.nyscStatus = status.value;
      const existingIndex = this.addedNetworks.findIndex((network) => {
        return network.site === event.value;
      });

      if (existingIndex > -1) {
        // this.addedNetworks[i].site = "";
        // this.addedNetworks.splice(i, 1);
        // this.addedNetworks.push({ site: "", url: "" });
        this.$handleError('This site already added!');

        return;
      }

      this.addedNetworks[i].site = event.value;
      console.log(this.addedNetworks[i].site);
    },
    proceed() {
      this.setSocialNetworks(this.addedNetworks);
      this.$emit('incrementStep');
    },
    addMoreNetwork(i) {
      if (this.addedNetworks.length < this.socialNetworks.length) {
        this.addedNetworks.push({ site: '', url: '' });
      }
    },
    removeNework(i) {
      this.addedNetworks.splice(i, 1);
    }
  }
};
</script>

<style scoped></style>
