<template>
  <div>
    <span class="fw-600 d-block fs-18 mb-2 mt-2">Enter Course of Study</span>

    <div
      v-for="(data, index) in formData"
      :key="index"
      class="grid grid__layout grid__mobile gap-3"
      :class="{ 'bt-1 pt-3 mt-3': index }"
    >
      <template v-if="index">
        <div class="col-6 m-col-6" />
        <div
          class="col-6 m-col-6 text-right"
          @click="formData.splice(index, 1)"
        >
          <p class="text-red cursor-pointer">Remove</p>
        </div>
      </template>
      <div class="col-6 m-col-12">
        <div class="fs-14 fw-500 text__dark-grey mb-1">
          Degree/Certification
        </div>
        <Dropdown
          :options="degrees"
          @selected="updateSelected($event, index)"
          placeholder="Select your Degree/Certification"
          :border="true"
        />
      </div>
      <div class="col-6 m-col-12">
        <div class="fs-14 fw-500 text__dark-grey mb-1">
          College/University/Academy
        </div>
        <Input
          type="text"
          placeholder="College/University/Academy"
          v-model="formData[index].institute"
          name="College"
          rules="required"
        />
      </div>
      <div class="col-6 m-col-12">
        <div class="fs-14 fw-500 text__dark-grey mb-1">Start Date</div>
        <Datepicker
          v-model="formData[index].startDate"
          name="Start Date"
          placeholder="Start Date"
          :disabled-dates="disableFuture"
        />
      </div>
      <div class="col-6 m-col-12">
        <div class="fs-14 fw-500 text__dark-grey mb-1">End Date</div>
        <Datepicker
          v-model="formData[index].endDate"
          name="End Date"
          placeholder="End Date"
          :disabled="!formData[index].startDate"
          :disabled-dates="disabled_dates(index)"
        />
      </div>

      <div class="col-12">
        <div class="fs-14 fw-500 text__dark-grey mb-1">Course of Study</div>

        <textarea
          placeholder="Course of Study"
          style="width: 100%"
          class="h-18 p-2"
          v-model="formData[index].details"
        />
      </div>
    </div>

    <div class="mt-3 flex flex__center">
      <Button theme="dashed" @click="addMoreEducation" class="w-23">
        <div class="flex" style="margin-right: 10px">
          <img src="../../../../assets/icons/add-icon.svg" alt="add-icon" />
        </div>
        <span class="fs-14 fw-600" style="color: #8b90a0">
          Add more education
        </span>
      </Button>
    </div>
    <div class="bb-1 my-3" />
    <div class="flex flex__justify-end onboarding__submit-btn">
      <Button theme="grey" @click="$emit('decrementStep')" class="w-23">
        Go Back
      </Button>
      <Button
        theme="blue"
        @click="submitEducation"
        class="ml-2 w-23"
        :disabled="!formValid || loading"
        :loading="loading"
      >
        Proceed
      </Button>
    </div>
  </div>
</template>

<script>
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Datepicker from 'vuejs-datepicker';
import { mapActions, mapMutations } from 'vuex';
import authService from '../../../../services/api/authService';

export default {
  name: 'Education',
  props: {
    onboardingData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Input,
    Button,
    Datepicker
  },
  data() {
    return {
      degrees: [],
      formData: [
        {
          degreeId: '',
          institute: '',
          startDate: '',
          endDate: '',
          details: '',
          stillGoing: false
        }
      ],
      loading: false
    };
  },
  computed: {
    formValid() {
      const fields = ['degreeId', 'institute', 'startDate', 'endDate'];

      return this.formData.every((data) =>
        fields.every((field) => data[field])
      );
    },
    disableFuture() {
      return {
        ranges: [
          {
            from: new Date(),
            to: new Date(3000, 11, 30)
          }
        ]
      };
    }
  },
  methods: {
    ...mapMutations({
      setEducations: 'authnew/setEducations'
    }),
    ...mapActions({
      addEducation: 'apimodel/makeRequest'
    }),
    disabled_dates(index) {
      const date = this.formData[index].startDate
        ? [
            new Date(this.formData[index].startDate).getFullYear(),
            new Date(this.formData[index].startDate).getMonth(),
            new Date(this.formData[index].startDate).getDate() + 1
          ]
        : [new Date()];

      return {
        ranges: [
          {
            from: new Date(0, 0, 0),
            to: new Date(...date)
          },
          {
            from: new Date(),
            to: new Date(3000, 11, 30)
          }
        ]
      };
    },
    updateSelected({ value }, index) {
      this.formData[index].degreeId = value;
    },
    submitEducation() {
      this.setEducations(this.formData);
      this.$emit('incrementStep');
    },
    addMoreEducation() {
      this.formData.push({
        degreeId: '',
        institute: '',
        startDate: '',
        endDate: '',
        details: '',
        stillGoing: false
      });
    }
  },
  mounted() {
    authService
      .getDegrees()
      .then((res) => {
        this.degrees = res.data.degrees.map((degree) => ({
          name: degree.title,
          value: degree.id
        }));
      })
      .catch()
      .finally();
  }
};
</script>

<style scoped></style>
