<template>
  <div>
    <div class="flex flex__item-center mt-2">
      <NewRadio
        name="experienced"
        id="experienced"
        value="experienced"
        @change="experienceStatus = $event"
        label="Experienced"
        :checked="true"
      />

      <NewRadio
        name="experienced"
        id="experienced1"
        value="noExperience"
        @change="experienceStatus = $event"
        label="No Experience"
        class="ml-2"
      />
    </div>

    <template v-if="experienceStatus === 'experienced'">
      <span class="fw-600 d-block fs-18 mb-2 mt-4">
        Enter Experience Details ---
      </span>
      <!-- {{ $getCountries }} -->

      <div
        class="grid grid__layout grid__mobile gap-3"
        v-for="(data, index) in formData"
        :key="index"
        :class="{ 'bt-1 pt-3 mt-3': index }"
      >
        <template v-if="index">
          <div class="col-6 m-col-6" />
          <div
            class="col-6 m-col-6 text-right"
            @click="formData.splice(index, 1)"
          >
            <p class="text-red cursor-pointer">Remove</p>
          </div>
        </template>
        <div class="col-6 m-col-12">
          <div class="fs-14 fw-500 text__dark-grey mb-1">Company Name</div>
          <Input
            type="text"
            placeholder="Employer/Company Name"
            v-model="formData[index].companyName"
            name="Company name"
          />
        </div>
        <div class="col-6 m-col-12 u-above z-21">
          <div class="fs-14 fw-500 text__dark-grey mb-1">Position / Role</div>

          <Input
            type="text"
            placeholder="Job Title"
            v-model="formData[index].jobTitle"
            name="Job Title"
          />
        </div>
        <div class="col-6 m-col-12 u-above" style="z-index: 99 !important">
          <div class="fs-14 fw-500 text__dark-grey mb-1">Country</div>

          <Dropdown
            :options="countries"
            @selected="updateSelected($event, 'countryId', index)"
            placeholder="Country"
            :border="true"
            zIndex="20"
          />
        </div>
        <div class="col-6 m-col-12 z-22" style="z-index: 98 !important">
          <div class="fs-14 fw-500 text__dark-grey mb-1">State</div>

          <Dropdown
            :options="cities"
            @selected="updateSelected($event, 'stateId', index)"
            :disabled="formData[index].countryId === null"
            placeholder="State"
            :border="true"
          />
        </div>
        <div class="col-6 m-col-12" style="z-index: 97 !important">
          <div class="fs-14 fw-500 text__dark-grey mb-1">Work Type</div>

          <Dropdown
            :options="workTypes"
            @selected="updateSelected($event, 'workType', index)"
            placeholder="Work Type"
            :border="true"
          />
        </div>

        <div class="col-6 m-col-12" style="z-index: 96 !important">
          <div class="fs-14 fw-500 text__dark-grey mb-1">Industry</div>
          <!-- 
            TODO: replace with dropdown
           -->
          <!-- <Input
            type="text"
            placeholder="Industry"
            v-model="formData[index].industry"
            name="Industry"
          /> -->

          <Dropdown
            :options="industryList"
            @selected="setIndustry($event, index)"
            placeholder="Industry"
            :border="true"
          />
        </div>
        <div class="col-6 m-col-12" style="z-index: 95 !important">
          <div class="fs-14 fw-500 text__dark-grey mb-1">Start Date</div>

          <Datepicker
            v-model="formData[index].startDate"
            name="Start Date"
            placeholder="Start Date"
            :disabled-dates="disableFuture"
          />
        </div>
        <div class="col-6 m-col-12" style="z-index: 94 !important">
          <div
            class="fs-14 fw-500 text__dark-grey mb-1"
            v-if="!formData[index].stillWorking"
          >
            End Date
          </div>

          <Datepicker
            v-if="!formData[index].stillWorking"
            v-model="formData[index].endDate"
            name="End Date"
            placeholder="End Date"
            :disabled="!formData[index].startDate"
            :disabled-dates="disabled_dates(index)"
          />
        </div>
        <div class="col-6 m-col-12">
          <div class="flex flex__justify-end">
            <p>Still working here</p>
            &nbsp;&nbsp;
            <NewToggle
              :id="'still-working' + index"
              v-model="formData[index].stillWorking"
              :defaultValue="formData[index].stillWorking"
            />
          </div>
        </div>
        <div class="col-6" />
        <div class="col-12 m-col-12 mt-2">
          <div class="fs-14 fw-500 text__dark-grey mb-1">Description</div>

          <textarea
            class="p-2 h-16"
            placeholder="Job Description"
            v-model="formData[index].description"
            maxlength="1000"
          />

          <div class="text-right">
            {{ formData[index].description.length }} / min(15)
          </div>
        </div>
      </div>
      <div class="mt-3 flex flex__center">
        <Button theme="dashed" @click="addMoreExperience" class="w-23">
          <div class="flex" style="margin-right: 10px">
            <img src="../../../../assets/icons/add-icon.svg" alt="add-icon" />
          </div>
          <span class="fs-14 fw-600" style="color: #8b90a0">
            Add more experience
          </span>
        </Button>
      </div>
    </template>

    <div class="bb-1 my-3" />
    <div class="flex flex__justify-end onboarding__submit-btn">
      <Button theme="grey" @click="$emit('decrementStep')" class="w-23">
        Go Back
      </Button>
      <Button
        theme="blue"
        @click="proceed"
        :disabled="
          (!formValid || loading) && experienceStatus != 'noExperience'
        "
        :loading="loading"
        class="ml-2 w-23"
      >
        Proceed
      </Button>
    </div>
  </div>
</template>

<script>
import Input from '../../../../components/Input';
import Toggle from '../../../../components/Toggle';
import Datepicker from 'vuejs-datepicker';
import { mapActions, mapMutations } from 'vuex';
// import API from "../../../../const";
// import jobseekerservice from "../../../../services/api/jobseekerservice";
import authService from '../../../../services/api/authService';
import publicService from '../../../../services/api/publicService';

export default {
  name: 'Experience',
  props: {
    onboardingData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Input,
    Datepicker,
    Toggle
  },
  // mixins: [countriesMixin],
  data() {
    return {
      industryList: [],
      experienceStatus: 'experienced',
      formData: [
        {
          companyName: '',
          jobTitle: '',
          workType: '',
          stillWorking: false,
          startDate: '',
          endDate: '',
          description: '',
          countryId: null,
          stateId: null,
          industry: ''
        }
      ],
      cities: [],
      workTypes: [
        { name: 'On Site', value: 'On Site' },
        { name: 'Hybrid', value: 'Hybrid' },
        { name: 'Remote', value: 'Remote' }
      ],
      loading: false,
      countries: []
    };
  },
  created() {
    this.getAllCountries();
  },
  computed: {
    // countries() {
    //   return this.$getCountries.map((country) => ({
    //     name: country.name,
    //     value: country.sortname,
    //     id: country.id
    //   }));
    // },
    formValid() {
      const fields = Object.keys(this.formData[0]);

      return this.formData.every((data) =>
        fields.every((field) => {
          if (field === 'endDate') {
            return data.stillWorking ? true : data[field];
          }

          if (field === 'stillWorking') {
            return true;
          }

          if (['countryId', 'stateId'].includes(field)) {
            return data[field] !== null;
          }

          if (field === 'description') {
            return data[field].length > 14;
          }

          return data[field];
        })
      );
    },
    disableFuture() {
      return {
        ranges: [
          {
            from: new Date(),
            to: new Date(3000, 11, 30)
          }
        ]
      };
    }
  },
  methods: {
    ...mapMutations({
      setExperiences: 'authnew/setExperiences'
    }),
    ...mapActions({
      addExperiences: 'apimodel/makeRequest'
    }),
    updateSelected({ value, id = '' }, key, index) {
      console.log(key);
      if (key === 'countryId') {
        this.formData[index][key] = id;

        authService
          .getStates(id)
          .then((res) => {
            this.cities = res.data.country.states.map((city) => ({
              ...city,
              value: city.name
            }));
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (key === 'stateId') {
        this.formData[index][key] = this.cities.find(
          (city) => city.value === value
        ).id;
      } else {
        this.formData[index][key] = value;
      }
    },
    setIndustry(evt, index) {
      this.formData[index].industry = evt.name;
    },
    proceed() {
      if (this.experienceStatus === 'experienced') {
        this.setExperiences(this.formData);
      } else {
        this.setExperiences([]);
      }

      this.$emit('incrementStep');
    },
    disabled_dates(index) {
      const date = this.formData[index].startDate
        ? [
            new Date(this.formData[index].startDate).getFullYear(),
            new Date(this.formData[index].startDate).getMonth(),
            new Date(this.formData[index].startDate).getDate() + 1
          ]
        : [new Date()];

      return {
        ranges: [
          {
            from: new Date(0, 0, 0),
            to: new Date(...date)
          },
          {
            from: new Date(),
            to: new Date(3000, 11, 30)
          }
        ]
      };
    },
    addMoreExperience() {
      this.formData.push({
        companyName: '',
        jobTitle: '',
        workType: '',
        salary: '',
        stillWorking: false,
        startDate: '',
        endDate: '',
        description: '',
        countryId: null,
        Iindustry: ''
      });
    },
    getAllCountries() {
      authService
        .getAllCountries()
        .then((res) => {
          this.countries = res.data.countries;
        })
        .catch()
        .finally();
    },
    getIndustryList() {
      publicService
        .getIndustyList()
        .then((res) => {
          //   this.countries = res.data.countries;
          //   console.log(res.data);
          // TODO: set real industry from api
          this.industryList = res.data.industries.map((industry) => ({
            name: industry.title,
            label: industry.title
          }));
          // this.industryList = [
          //   { name: "Industry type1", label: "Industry type1" },
          //   { name: "Industry type2", label: "Industry type2" },
          //   { name: "Industry type3", label: "Industry type3" }
          // ];
        })
        .catch()
        .finally();
    }
  },

  mounted() {
    this.getIndustryList();
  }
};
</script>

<style scoped></style>
