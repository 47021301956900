<template>
  <div>
    <span class="fw-600 fs-18 mb-2 d-block">Profile Summary</span>
    <textarea
      placeholder="Enter profile summary here"
      style="width: 100%"
      class="h-18 p-2"
      v-model="formData.profileSummary"
    />
    <div class="bb-1 my-3" />
    <div class="flex onboarding__submit-btn">
      <Button
        @click="proceed"
        class="w-23 profile__summary-btn"
        :disabled="!formData.profileSummary || loading"
        theme="blue"
        :loading="loading"
      >
        Proceed
      </Button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import Button from '../../../../components/Button';

export default {
  name: 'ProfileSummary',
  props: {
    onboardingData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Button
  },
  data() {
    return {
      formData: {
        profileSummary: ''
      },
      loading: false
    };
  },
  methods: {
    ...mapMutations({
      setProfileSummary: 'authnew/setProfileSummary'
    }),

    proceed() {
      this.setProfileSummary(this.formData.profileSummary);
      this.$emit('incrementStep');
    }
  }
};
</script>

<style scoped></style>
