export default {
    data() {
        return {
            onMobileView: false,
            resizeObserver: {},
        };
    },
    created() {
        this.screenWidthObserver();
    },
    methods: {
        screenWidthObserver() {
            // Observes the width of the page
            this.resizeObserver = new ResizeObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.contentRect.width <= 540) {
                        this.onMobileView = true;

                        return;
                    }

                    this.onMobileView = false;
                });
            });

            this.resizeObserver.observe(document.body);
        },
    },
};
