<template>
  <div>
    <!-- <div style="overflow-y: auto"> -->
    <Header class="py-1" />

    <div class="flex onboarding__tab-wrapper" id="tab-wrapper">
      <div
        class="onboarding__tab flex flex__item-center"
        v-for="(step, index) in steps"
        :key="index"
        :id="`tab-${index + 1}`"
        :class="{ active: currentStep - 1 >= index }"
      >
        <div
          class="flex flex__column flex__item-center onboarding__tab-content"
        >
          <div
            class="onboarding__tab-icon-wrap mb-1 flex flex__center flex__item-center"
          >
            <div class="onboarding__tab-icon">
              <img
                src="../../../assets/icons/check-icon.svg"
                alt="check-icon"
              />
            </div>
          </div>
          <span style="white-space: nowrap">{{ step }}</span>
        </div>
        <div class="onboarding__tab-spacer">
          <div class="w-10 item" />
        </div>
      </div>
    </div>

    <main class="onboarding__wrapper">
      <template>
        <!-- <div class="flex flex__justify-end mt-4">
          <Button theme="blue" @click="incrementStep" class="ml-2 w-23">
            Force Proceed
          </Button>
        </div> -->
        <transition name="fade-from-top" mode="out-in">
          <keep-alive>
            <component
              @data="formData = { ...formData, ...$event }"
              @incrementStep="incrementStep"
              @decrementStep="decrementStep"
              :is="activeView"
              :onboardingData="onboardingData"
            />
          </keep-alive>
        </transition>
      </template>
    </main>
  </div>
</template>

<script>
import Header from '../../../components/Header';
import ProfileSummary from './Steps/ProfileSummary.vue';
import Education from './Steps/Education.vue';
import Experience from './Steps/Experience.vue';
import SocialNetworks from './Steps/SocialNetworks.vue';
import CV from './Steps/CV.vue';
import KeySkills from './Steps/KeySkills.vue';
import MyProfile from './Steps/MyProfile.vue';
import MediaQuery from '@/mixins/mediaQuery';
import { mapGetters } from 'vuex';

export default {
  name: 'Onboarding',
  mixins: [MediaQuery],
  components: {
    Header,
    ProfileSummary,
    Education,
    Experience,
    SocialNetworks,
    CV,
    KeySkills,
    MyProfile
  },
  data() {
    return {
      formData: {},
      activeView: 'MyProfile',
      steps: [
        // "Profile Summary",
        'My Profile',
        'Education',
        'Experience',
        'Social Networks',
        'CV',
        'Key Skills'
      ],
      currentStep: 1
    };
  },

  computed: {
    ...mapGetters({
      onboardingData: 'authnew/getOnboardingData'
    })
  },
  created() {
    window.onbeforeunload = function () {
      return "Don't leave";
    };
  },
  methods: {
    incrementStep() {
      this.currentStep++;
    },
    decrementStep() {
      this.currentStep--;
    },
    scrollTabHandler() {
      // Handles the scrolling of the tabs to view when an action is taken
      const tabWrapper = document.getElementById('tab-wrapper');
      const tab = document.getElementById(`tab-${this.currentStep}`);
      const tabOffsetPosition = tab.offsetLeft;
      tabWrapper.scrollLeft = tabOffsetPosition - 20;
    }
  },
  watch: {
    currentStep(value) {
      this.activeView = this.steps[value - 1].replaceAll(' ', '');

      if (this.onMobileView) this.scrollTabHandler();
    }
  },
  destroyed() {
    window.onbeforeunload = function () {};
    this.resizeObserver.unobserve(document.body);
  }
};
</script>

<style scoped></style>
