<template>
  <div>
    <span class="fw-600 d-block fs-18 mb-2 mt-2">
      Enter Personal Information
    </span>

    <ValidationObserver v-slot="{ invalid }">
      <div class="grid grid__layout grid__mobile gap-3">
        <div class="col-6 m-col-12">
          <div class="fs-14 fw-500 text__dark-grey mb-1">Full Name</div>

          <Input
            type="text"
            placeholder="Full Name"
            v-model="formData.fullName"
            name="Full name"
            rules="required|fullName"
          />
        </div>
        <div class="col-6 m-col-12">
          <div class="fs-14 fw-500 text__dark-grey mb-1">Email</div>

          <Input
            type="email"
            placeholder="Email"
            :value="userData.user.email"
            name="Email"
            rules="required|email"
            disabled
          />
        </div>

        <div class="col-4 m-col-12">
          <div class="fs-14 fw-500 text__dark-grey mb-1">
            Years of experience
          </div>

          <Dropdown
            :options="experiences"
            @selected="setYearsOfExperience"
            placeholder="Years of experience"
            :border="true"
          />
        </div>

        <div class="col-4 m-col-12">
          <div class="fs-14 fw-500 text__dark-grey mb-1">Position</div>

          <Input
            type="text"
            placeholder="Position"
            v-model="formData.position"
            name="Position"
            rules="required"
          />
        </div>

        <div class="col-4 m-col-12">
          <div class="fs-14 fw-500 text__dark-grey mb-1">Industry</div>
          <Dropdown
            :options="industryList"
            @selected="updateSelected($event, 'industry')"
            :placeholder="formData.industry || 'Industry'"
            :border="true"
          />
        </div>

        <div class="col-12">
          <div class="fs-14 fw-500 text__dark-grey mb-1">Address</div>

          <textarea
            class="h-10 p-2"
            placeholder="Address"
            v-model="formData.address"
            rules="required"
          ></textarea>
        </div>

        <div class="col-6 m-col-12" style="z-index: 99">
          <div class="fs-14 fw-500 text__dark-grey mb-1">Country</div>

          <Dropdown
            :options="countries"
            @selected="updateSelected($event, 'countryId')"
            placeholder="Country"
            :border="true"
          />
        </div>
        <div class="col-6 m-col-12" style="z-index: 98">
          <div class="fs-14 fw-500 text__dark-grey mb-1">State</div>

          <Dropdown
            :options="cities"
            @selected="updateSelected($event, 'stateId')"
            :disabled="formData.countryId === null"
            placeholder="State"
            :border="true"
          />
        </div>
        <div class="col-6 m-col-12" style="z-index: 97">
          <!-- <Input
            type="number"
            placeholder="Mobile Number"
            v-model="formData.mobile_number"
            name="Mobile Number"
            rules="required"
          /> -->
          <div class="fs-14 fw-500 text__dark-grey mb-1">Languages</div>

          <selection-box
            @selected="setLanguages"
            label="Languages"
            :options="languages"
          />
        </div>
        <div class="col-6 m-col-12" style="z-index: 96">
          <div class="fs-14 fw-500 text__dark-grey mb-1">Gender</div>

          <Dropdown
            :options="genders"
            @selected="setGender"
            placeholder="Select Gender"
            :border="true"
          />
        </div>
        <div class="col-12">
          <div class="fs-14 fw-500 text__dark-grey mb-1">Date of Birth</div>
          <div class="grid grid__layout grid__mobile gap-2">
            <div class="col-4 m-col-12" style="z-index: 95">
              <Dropdown
                :options="days"
                @selected="setDay"
                placeholder="Day"
                :border="true"
              />
            </div>
            <div class="col-4 m-col-12" style="z-index: 94">
              <Dropdown
                :options="months"
                @selected="setMonth"
                placeholder="Month"
                :border="true"
              />
            </div>
            <div class="col-4 m-col-12" style="z-index: 93">
              <Dropdown
                :options="years.slice(18)"
                @selected="setYear"
                placeholder="Years"
                :border="true"
              />
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="fs-14 fw-500 text__dark-grey mb-1">
            National Youth Service Corps (NYSC) status
          </div>

          <Dropdown
            :options="nyscStatuses"
            @selected="setNyscStatus"
            placeholder="Select National Youth Service Corps (NYSC) status"
            :border="true"
          />
        </div>

        <div class="col-12">
          <div class="fs-14 fw-500 text__dark-grey mb-1">Profile Summary</div>

          <textarea
            placeholder="Enter profile summary here"
            style="width: 100%"
            class="h-18 p-2"
            v-model="formData.profileSummary"
            rules="required"
            maxlength="1000"
          />
          <div class="text-right">Max 1000 characters</div>
        </div>

        <!-- <div class="col-6 m-col-12" style="z-index: 92">
          <Dropdown
            :options="genders"
            @selected="setGender"
            placeholder="Select Gender"
            :border="true"
          />
        </div> -->

        <!-- <div class="col-6 m-col-12">
                    <span class="fw-600 d-block mt-2 mb-1">Date of Birth</span>
                    <DatePicker
                        v-model="formData.dateOfBirth"
                        name="Date of birth"
                        placeholder="Date of birth"
                    />
                </div> -->
      </div>

      <div class="bb-1 my-3" />
      <div class="flex flex__justify-end onboarding__submit-btn">
        <!-- <Button theme="grey" @click="$emit('decrementStep')" class="w-23">
          Go Back
        </Button> -->
        <Button
          :disabled="invalid || buttonDisabled || loading || !formData.industry"
          :loading="loading"
          theme="blue"
          @click="submitProfile"
          class="ml-2 w-23"
        >
          Proceed
        </Button>

        <!-- <Button
          :loading="loading"
          theme="blue"
          @click="submitProfile"
          class="ml-2 w-23"
        >
          Force Complete Profile
        </Button> -->
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import Input from '../../../../components/Input';
import SelectionBox from '../../../../components/SelectionBox';
import DatePicker from 'vuejs-datepicker';
// import { countries, countriesAndStates } from "../../../../utils/countries";
import { mapActions, mapGetters, mapMutations } from 'vuex';
import API from '../../../../const';
// import jobseekerservice from "../../../services/api/jobseekerservice";
// import jobseekerservice from "../../../../services/api/jobseekerservice";
import publicService from '../../../../services/api/publicService';
import authService from '../../../../services/api/authService';
import { languages } from '../../../../__mocks__/languages';
import { days, months, years } from '../../../../__mocks__/dates';

export default {
  name: 'MyProfile',
  props: {
    onboardingData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Input,
    DatePicker,
    SelectionBox
  },
  data() {
    return {
      industryList: [],
      nyscStatuses: [
        { name: 'Completed', value: 'Completed' },
        { name: 'Exempted', value: 'Exempted' },
        { name: 'In progress', value: 'In progress' },
        { name: 'Not applicable', value: 'Not applicable' }
      ],
      formData: {
        fullName: '',
        phone: '',
        countryId: null,
        stateId: null,
        languages: [],
        linkedin_profile: '',
        dateOfBirth: '',
        yearsOfExperience: '',
        position: '',
        gender: '',
        address: '',
        nyscStatus: '',
        profileSummary: '',
        industry: ''
      },
      countries: [],
      cities: [],
      loading: false,
      languages,
      genders: [
        { name: 'Male', value: 'Male' },
        { name: 'Female', value: 'Female' },
        { name: 'Other', value: 'Other' }
      ],
      days,
      months,
      years,
      day: '',
      month: '',
      year: '',
      experiences: [
        { name: '1 Year', value: '1 Year' },
        { name: '2 Years', value: '2 Years' },
        { name: '3 Years', value: '3 Years' },
        { name: '4 Years', value: '4 Years' },
        { name: '5 Years', value: '5 Years' },
        { name: '6 Years', value: '6 Years' },
        { name: '7 Years', value: '7 Years' },
        { name: '8 Years', value: '8 Years' },
        { name: '9 Years', value: '9 Years' },
        { name: '10 Years', value: '10 Years' },
        { name: '10+ Years', value: '10+ Years' }
      ]
    };
  },
  created() {
    // const { email, name } = this.loggedUser;
    // this.formData.fullName = name;
    // this.formData.email = email;
    this.getAllCountries();
    this.getIndustryList();
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/getLoggedUser',
      userData: 'authnew/getUserData'
    }),
    buttonDisabled() {
      console.log(
        this.formData.languages.length,
        this.day,
        this.month,
        this.year
      );

      if (
        this.formData.languages.length === 0 ||
        !this.day ||
        (!this.month && this.month != 0) ||
        !this.year
      ) {
        return true;
      }

      const fields = [
        'countryId',
        'stateId',
        'gender',
        'nyscStatus',
        'profileSummary'
      ];

      return !fields.every((field) => {
        if (fields.includes(field)) {
          return this.formData[field] !== null && this.formData[field] !== '';
        }

        return this.formData[field];
      });
    }
  },
  methods: {
    ...mapActions({
      updateProfile: 'apimodel/makeRequest'
    }),
    ...mapMutations({
      updateLoggedUser: 'auth/updateLoggedUser',
      setMyProfile: 'authnew/setMyProfile',
      setUserData: 'authnew/setUserData'
    }),
    getIndustryList() {
      publicService
        .getIndustyList()
        .then((res) => {
          // TODO: set real industry from api
          this.industryList = res.data.industries.map((industry) => ({
            name: industry.title,
            label: industry.title
          }));
          F;
        })
        .catch()
        .finally();
    },
    updateSelected({ value, id = '', name }, key) {
      if (key === 'countryId') {
        this.formData.stateId = null;
        this.formData[key] = id;

        authService
          .getStates(id)
          .then((res) => {
            this.cities = res.data.country.states.map((city) => ({
              ...city,
              value: city.name
            }));
          })
          .catch((e) => {
            console.log(e);
          });

        return;
      }

      if (key === 'industry') {
        console.log(name);
        if (name) {
          setTimeout(() => {
            this.formData.industry = name;
          }, 111);
        }

        return;
      }

      this.formData[key] = id;
    },
    submitProfile() {
      const dob = new Date(this.year - 0, this.month - 1, this.day - 0);
      this.formData.dateOfBirth = dob;

      this.setMyProfile(this.formData);
      this.$emit('incrementStep');
    },
    setLanguages(languages) {
      // console.log(languages);
      this.formData.languages = languages.map((ln) => ln.name);
      // console.log(this.formData);
    },
    setGender(gender) {
      this.formData.gender = gender.value;
    },
    setDay(day) {
      this.day = day.value;
    },
    setMonth(month) {
      this.month = month.value - 1;
    },
    setYear(year) {
      this.year = year.value;
    },
    getAllCountries() {
      authService
        .getAllCountries()
        .then((res) => {
          this.countries = res.data.countries;
        })
        .catch()
        .finally();
    },
    setNyscStatus(status) {
      this.formData.nyscStatus = status.value;
    },
    setYearsOfExperience(option) {
      this.formData.yearsOfExperience = option.value;
    }
  }
};
</script>

<style scoped>
.ai-center {
  align-items: center;
}

.link {
  color: blue;
  text-decoration: underline;
}
</style>
