<template>
  <div>
    <span class="fw-600 d-block fs-18 mb-2">Upload Documents</span>
    <FileGrabber
      insideText="Drop your CV here"
      v-model="document"
      :accept="['.doc', '.docx', '.pdf']"
    />
    <div class="bb-1 my-3" />
    <div class="flex flex__justify-end onboarding__submit-btn">
      <Button theme="grey" @click="$emit('decrementStep')" class="w-23">
        Go Back
      </Button>
      <!-- <Button
        theme="blue"
        @click="uploadDocument"
        :disabled="loading || !document"
        :loading="loading"
        class="ml-2 w-23"
      >
        Proceed
      </Button> -->
      <Button
        theme="blue"
        @click="uploadDocument"
        :disabled="!document"
        :loading="loading"
        class="ml-2 w-23"
      >
        Proceed
      </Button>
    </div>
  </div>
</template>

<script>
import FileGrabber from '../../../../components/FileGrabber';
import Button from '../../../../components/Button';
import API from '../../../../const';
import { mapActions } from 'vuex';
import seekerService from '../../../../services/api/seekerService';

export default {
  name: 'Documents',
  props: {
    onboardingData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    FileGrabber,
    Button
  },
  data() {
    return {
      document: '',
      loading: false,
      percentage: 0
    };
  },
  methods: {
    ...mapActions({
      addDocument: 'apimodel/makeRequest'
    }),
    uploadDocument() {
      // this.$emit("incrementStep");
      // return;

      const allowedFileTypes = ['pdf', 'PDF', 'doc', 'DOC', 'docx', 'DOCX'];

      const fileType = this.document.name.split('.').pop();
      if (allowedFileTypes.indexOf(fileType) < 0) {
        this.$handleError('Only DOC, DOCX and PDF file tyoes are allowed');
        return;
      }

      if (this.document.size / 1024 > 3 * 1024) {
        this.$handleError('Max file size allowed is 3MB');
        return;
      }

      this.loading = true;
      const formData = new FormData();
      formData.append('cv', this.document);

      seekerService
        .uploadCv(formData, (percentage) => {
          console.log(percentage);
        })
        .then((res) => {
          // this.$handleSuccess(res);
          this.$emit('incrementStep');
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped></style>
