<template>
  <div>
    <div class="pb-3 u-above z-23">
      <span class="fw-600 d-block fs-18 mb-2">Select Key Skills</span>
      <div class="grid grid__layout grid__mobile gap-2">
        <div class="col-6 m-col-12">
          <selection-box
            label="Type in to search or add new"
            :options="skills"
            type="search-box"
            @selected="updateSelection('skills', $event)"
          />
        </div>
      </div>
    </div>

    <div class="pb-3 mb-3 bb-1">
      <span class="fw-600 d-block fs-18 mb-2">Employment type</span>
      <div class="grid grid__layout grid__mobile gap-3">
        <div class="col-6 m-col-12 u-above z-21">
          <selection-box
            @selected="updateSelection('jobType', $event)"
            label="Choose employment type"
            :options="jobTypes"
          />
        </div>
        <div class="col-6" />

        <div class="col-6 m-col-12 u-above">
          <span class="fw-600 d-block fs-18 mb-2">Work Mode</span>
          <selection-box
            label="Choose work mode"
            :options="workTypes"
            @selected="updateSelection('workType', $event)"
          />
        </div>
      </div>
      <div class="flex ai-center mt-4">
        <Checkbox v-model="termsAgreed" class="mr-1" id="terms" />
        <label for="terms" class="">
          I agree to the
          <a href="/terms" class="link" target="_blank">
            terms and conditions
          </a>
        </label>
      </div>
    </div>

    <div class="flex flex__justify-end onboarding__submit-btn">
      <Button theme="grey" @click="$emit('decrementStep')" class="w-23"
        >Go Back</Button
      >
      <Button
        theme="blue"
        @click="completeProfile"
        class="ml-2 w-23"
        :disabled="buttonDisabled || loading || !termsAgreed"
        :loading="loading"
      >
        Complete Profile
      </Button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Input from '../../../../components/Input';
import SelectionBox from '../../../../components/SelectionBox';
import API from '@/const';
import Checkbox from '../../../../components/Checkbox';
import authService from '../../../../services/api/authService';
import publicService from '../../../../services/api/publicService';
import { setPrivateHaders } from '../../../../services';

export default {
  name: 'KeySkills',
  props: {
    onboardingData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Input,
    SelectionBox,
    Checkbox
  },
  data() {
    return {
      formData: {
        skills: [],
        workType: [],
        jobType: []
      },
      skills: [
        // { name: "C++", label: "C++" },
        // { name: "Javascript", label: "javascript" },
        // { name: "Python", label: "python" },
        // { name: "Node JS", label: "node_js" }
      ],
      jobTypes: [
        { name: 'Full Time', label: 'Full Time' },
        { name: 'Part Time', label: 'Part Time' }
      ],
      workTypes: [
        { name: 'On Site', label: 'On Site' },
        { name: 'Hybrid', label: 'Hybrid' },
        { name: 'Remote', label: 'Remote' }
      ],
      loading: false,

      termsAgreed: false
    };
  },
  computed: {
    ...mapGetters({ userData: 'authnew/getUserData' }),
    buttonDisabled() {
      const formKeys = Object.keys(this.formData);
      return !formKeys.every((key) => this.formData[key].length);
    }
  },
  methods: {
    ...mapMutations({
      setKeySkills: 'authnew/setKeySkills',
      setUserData: 'authnew/setUserData',
      setUserRequiredAction: 'authnew/setUserRequiredAction'
    }),
    ...mapActions({
      addSkills: 'apimodel/makeRequest'
    }),
    completeProfile() {
      this.setKeySkills(this.formData);
      this.loading = true;
      authService
        .onboard(this.onboardingData, this.userData.token)
        .then((res) => {
          this.$handleSuccess(res);
          // this.setUserData(res.data);
          // setPrivateHaders(res.data.token);
          this.setUserRequiredAction('');
          this.$router.push('/job-seeker/my-account/phone-verification');
        })
        .catch((e) => {
          this.$handleError(e);
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateSelection(key, data) {
      this.formData[key] = data.map((skill) => skill.label);
    }
  },
  mounted() {
    publicService
      .getSkillsList()
      .then((res) => {
        this.skills = res.data.skills.map((skill) => ({
          name: skill.title,
          label: skill.title
        }));
      })
      .catch((e) => {
        this.$handleError(e);
      })
      .finally(() => {});
  }
};
</script>

<style scoped>
.ai-center {
  align-items: center;
}

.link {
  color: blue;
  text-decoration: underline;
}
</style>
